.contact_layout {
    padding-top: 22vh;
    margin-bottom: 9vh;
    .data {
        height: 80vh;
        width: 100%;
        .informatat {
            padding-left: 3rem;
            justify-content: space-evenly;
            display: flex;
            flex-direction: column;
            .contact_title {
                font-family: "Roboto-Regular";
                font-weight: 700;
                font-size: 22px;
                text-align: initial;
                color: #232d58;
            }
            .kontakt_info {
                // justify-content: space-around;
                width: 80%;
                display: flex;
                flex-direction: column;
                margin-top: 1rem;

                flex-wrap: wrap;
            }
            i {
                margin-right: 5px;
                letter-spacing: 6px;
            }
            a {
                z-index: 9999;
                position: relative;
                color: #232d58;
                letter-spacing: 0.3px;
                text-decoration: none;
            }
            .welcoming_pharagraph {
                font-family: "Roboto-Regular";
                font-weight: 300;
                font-size: 16px;
                text-align: initial;
                margin: 1em auto 1em auto;
                color: #232d58;
            }
            .titujBolld {
                text-align: initial;
                font-family: "Roboto-Regular";
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                color: #232d58;
            }
            .tekstLight {
                font-family: "Roboto-Regular";
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                color: #232d58;
            }
        }
        .harta {
            padding: 0;
            iframe {
                height: 100%;
                width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .contact_layout {
        margin-top: 0;
        padding-top: 12vh;
        height: auto;
        margin-bottom: 0;
        .data {
            // min-height: 100vh;
            height: auto;
            width: fit-content;
            .informatat {
                padding-top: 2rem;
                padding-left: 2rem;
                i {
                    margin-top: 0.2rem;
                }
                .kontakt_info {
                    width: 90%;
                    i {
                        font-size: smaller;
                    }
                    p {
                        font-size: smaller;
                    }
                    a {
                        font-size: smaller;
                    }
                }
            }
        }
        .harta {
            height: 60vh;
        }
    }
}
