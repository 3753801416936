
:root {
	--red: #da2c4d;
	--yellow: #f8ab37;
	--green: #2ecc71;
	--white: #ffffff;
	--grey-light: #f2f7f9;
	--grey: #ecedf3;
	--black: #080808;
	--black-blue: #1f2029;
	--black-blue-light: #353746;
	--black-blue-light-2: #404255;
	--black-blue-light-3: #4b4d64;
	--black-light: #424455;
}
@-webkit-keyframes border-transform{
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
	14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
	28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
	42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
	56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
	70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
	84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
}
@keyframes border-transform{
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
	14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
	28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
	42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
	56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
	70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
	84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
}

/* #Progress
================================================== */

.progress-wrap {
	position: fixed;
	right: 50px;
	bottom: 50px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset  0 0 0 2px rgb(26, 34, 70);
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap::after {
    content: '';
	position: absolute;
	font-family: 'unicons';
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	color: var(--grey);
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 1;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::after {
	opacity: 0;
}
.progress-wrap::before {
    content: '';
	position: absolute;
	font-family: 'unicons';
	text-align: center;
	line-height: 46px;
	font-size: 24px;
	opacity: 0;
	background-image: linear-gradient(298deg, var(--red), var(--yellow));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	left: 0;
	top: 0;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	z-index: 2;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
.progress-wrap:hover::before {
	opacity: 1;
}
.progress-wrap svg {
    height: 37px;
    margin: 5px auto;
    display: flex;
    margin: 5px auto;
   
 }
.progress-wrap svg path { 
	fill:  rgb(26, 34, 70);
    height: 58px;
}
.progress-wrap svg.progress-circle path {
	stroke: var(--grey);
	stroke-width: 4;
	box-sizing:border-box;
	-webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}
body.light .progress-wrap {
	box-shadow: inset  0 0 0 2px rgba(0,0,0,0.2);
}
body.light .progress-wrap::after {
	color: var(--black-blue);
}
body.light .progress-wrap svg.progress-circle path {
	stroke: var(--black-blue);
}