.news_map_layout {
    .latest_news_heading {
        color: #232d58;
        font-weight: 500;
        font-family: "Roboto-Regular";
        font-size: 40px;
        line-height: 45px;
    }
    .vizaNdarese {
        margin: 0px auto 10px auto;
        background: #232d58;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 2.06px;
    }
    .news_image {
        
        height: 380px;
        width: 100%;
        object-fit: cover;
    }
    .news-name {
        margin-top: 2vh;
        color: #232d58;
        font-weight: 500;
        font-family: "RobotoCondensedBold";
        font-size: 32px;
        line-height: 32px;
    }
    .news-location {
        margin-top: -10px !important;
        color: #232d58;
        font-size: 20px;
    }
    .news_description {
        font-family: "Roboto-Light";
        color: #232d58;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
    }
    .read_more_news {
        font-family: "Roboto-Bold";
        background: linear-gradient(71.76deg, #4571e0 -18.58%, #3b50ab 108.04%);
        padding: 9px 20px;
        border-radius: 2px;
        color: white;
        border: 0;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        margin-top: 2%;
        
    }
}
