.our_prducts_layout {
    .opening_parent_slider {
        .banner_layout {
            .single_product_image_banner {
                width: 100%;
                // position: absolute;
            }
            .banner_description {
                p {
                    position: absolute;
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 55px;
                    color: #ffffff;
                    top: 413px;
                }
            }
        }
    }
    .single_product_layout {
        .single_product_title {
            font-family: "Roboto-Medium";
            font-weight: 500;
            font-size: 44px;
            line-height: 55px;
            color: #232d58;
        }
        .single_product_description {
            font-family: "Roboto-Regular" !important;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            color: #232d58;
            .single_product_smaller_title {
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 26px;
                color: #232d58;
            }
        }
        .single_product_button {
            background: linear-gradient(46.81deg, #2e55ba -11%, #1a2246 168.73%);
            border-radius: 2px;
            color: white;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 125px;
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.01em;
        }
    }
}
@media only screen and (max-width: 800px) {
    .our_prducts_layout {
        .opening_parent_slider {
            .banner_layout {
                .single_product_image_banner {
                    object-fit: cover;
                    height: 70vh;
                }
                .banner_description {
                    // position: absolute;
                    // top: 50vh;
                    width: 90%;
                    font-weight: 700;
                    font-size: 32px;
                    color: #ffffff;
                    p {
                        line-height: 38px;
                        font-size: 25px;
                        left: auto;
                        right: auto;
                        top: 56% ;
                        font-size: 32px;
                        // bottom: -10rem;
                    }
                }
            }
        }
    }
    .single_product_layout {
        .single_product_title {
            font-size: 28px !important;
            margin-top: 1rem !important;
            line-height: 20px !important;
        }
        .single_product_button:nth-child(odd) {
            margin-bottom: 2rem;
        }
    }
}
@media only screen and (min-width: 2560px) {
    .single_product_layout {
        .banner_layout {
            .banner_description {
                p {
                    bottom: 20rem;
                }
            }
        }
    }
}
