.mirenjohjet_layout {
    padding-top: 6rem;
    .mirenjohjet_title {
        color: #232d58;
        font-size: 40px;
        font-weight: 500;
        text-align: initial;
        line-height: 55px;
    }
    .mirenjohjet_text {
        font-family: "Roboto-Regular";
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #232d58;
    }
}

@media only screen and (max-width: 800px) {
    .mirenjohjet_layout {
        padding-top: 5rem;
        .mirenjohjet_title {
            font-size: 24px;
            line-height: normal;
            text-align: center;
        }
    }
}
