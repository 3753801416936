.commingSoonBody {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    overflow: hidden;
    margin: 0;

    .content {
        width: 100%;
        justify-content: center;
        display: flex;
    }
    marquee {
        z-index: 99;
    }
    .weWillBeOnlineSoon {
        margin: 50px auto 0px auto;
        width: fit-content;
        margin-top: 50px;
        z-index: 9;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 147.69%;
        text-align: center;
        background: radial-gradient(137.27% 57044.63% at 52.28% 130.91%, #273061 0%, rgba(39, 48, 97, 0) 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .bgImg {
        position: fixed;
        height: 65%;
        bottom: 30%;
        top: 5%;
    }
    footer {
        height: 25%;
        width: 100%;
        background-color: red;
        position: fixed;
        bottom: 0px;
        background: linear-gradient(83.17deg, #090e21 -49.91%, #182354 62.75%);
    }
    .footerCenter {
        margin: auto;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    .footerText {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
        opacity: 0.9;
        text-align: center;
        margin-top: 50px;
        display: flex;
        width: fit-content;
    }
    .vija {
        margin: 0;
        margin-left: 10px;
        margin-right: 10px;
    }
    .companyLogoFooter {
        height: 80px;
        display: flex;
        margin: auto;
    }
}
@media only screen and (max-width: 800px) {
    .weWillBeOnlineSoon {
        word-break: unset;
        font-size: 30px;
        margin-top: 100px;
    }
    .bgImg {
        /* object-fit: cover; */
        width: 300%;
        height: 70%;
        top: 0;
    }
    footer {
        height: 30%;
    }
    .footerCenter {
        text-align: center;
        display: block;
    }
    .footerText {
        margin-top: 5px;
        justify-content: center;
    }
    .footerText:first-child {
        margin-top: 13px;
    }
    .companyLogoFooter {
        margin-top: 0px !important;
    }
    .vija {
        display: none;
    }
}
