.single_news_layout {
    .news_title {
        padding-top: 7rem;
        font-size: 40px;
        font-weight: 500;
        color: #232d58;
        line-height: 40px;
        font-family: "RobotoCondensedRegular";
    }
    .news_date {
        margin-top: -10px;
        font-size: 16px;
        font-weight: 400;
        color: #232d58;
        line-height: 26px;
        font-family: "RobotoCondensedRegular";
    }
    div {
        p {
            .desc_heading {
                font-family: "RobotoCondensedBold";
                color: #232d58;
                font-size: 20px;
                font-weight: 700;
                line-height: 16px;
                .desc_text {
                    font-family: "Roboto-Light";
                    line-height: 26px;
                    color: #232d58;
                    font-size: 16px;
                    font-weight: 300;
                }
            }
            .desc_text {
                font-family: "Roboto-Light";
                line-height: 26px;
                color: #232d58;
                font-size: 16px;
                font-weight: 300;
                .desc_heading {
                    font-family: "RobotoCondensedBold";
                    color: #232d58;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 16px;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .single_news_layout {
        .desc_text {
            line-height: 16px;
        }
    }
}
