.single_product_layout {
    .banner_layout {
        .single_product_image_banner {
            width: 100%;
            // position: absolute;
        }
        .banner_description {
            margin-top: -5rem;
            p {
                font-weight: 700;
                font-size: 50px;
                color: #ffffff;
            }
        }
    }
    .single_product_description_main_page {
        font-family: "Roboto-Regular";
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #232d58;
        // padding-top: 37rem;
    }
    .single_product_description {
        padding-top: 2.5rem;
        .single_product_title {
            font-style: normal;
            font-weight: 700;
            font-size: 44px;
            line-height: 55px;
            color: #232d58;
        }
        .product_text {
            font-family: "Roboto-Regular";
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            color: #232d58;
        }
        .single_product_smaller_title {
            font-family: "Roboto-Regular";

            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            color: #232d58;
        }
        .partneretTane {
            text-align: center;
            img {
                margin: 1% 1%;
                width: 132px;
                height: 61px;
            }
        }
        .openPDFparagraph {
            font-family: "Roboto-Regular";
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #232d58;
            cursor: pointer;
            text-decoration: none;
            i {
                font-size: 1.6em;
                padding-right: 0.3em;
            }
        }
    }
    .galleria {
        background: #f2f2f2;
        .galleria_title {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 55px;
            color: #222222;
        }
        .galleria_image_number {
            font-style: normal;
            font-weight: 300;
            font-size: 36px;
            line-height: 55px;
            text-align: right;
            color: #222222;
        }
        .slick-slider {
            .slick-prev {
                border: solid 2px #b2b2b2;
                border-radius: 50px;
                top: -8.5%;
                left: 84% !important;
                width: 40px;
                height: 40px;
                background-color: white;
                &::before {
                    content: "<";
                    color: black;
                    font-size: 20px;
                    font-weight: 900;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: x-large;
                }
            }
            .slick-next {
                border: solid 2px #b2b2b2;
                border-radius: 50px;
                top: -8.5%;
                right: 9% !important;
                width: 40px;
                height: 40px;
                background-color: white;

                &::before {
                    content: ">";
                    color: black;
                    font-size: 20px;
                    font-weight: 900;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: x-large;
                }
            }
            .slick-list {
                .slick-track {
                    .slick-slide {
                        div {
                            .single_project_slider {
                                width: 100% !important;
                                margin: auto;
                                max-height: 500px;
                                min-height: 500px;
                                object-fit: contain;
                                background: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        .react-3d-carousel .slider-container .slider-right div {
            border-top: 2px solid #83b4cd;
            border-right: 2px solid #83b4cd;
            border-bottom: 2px solid #83b4cd;
            border-left: 2px solid #83b4cd;
        }
        .react-3d-carousel .slider-container .slider-left div {
            i {
            }
            border-top: 2px solid #83b4cd;
            border-right: 2px solid #83b4cd;
            border-bottom: 2px solid #83b4cd;
            border-left: 2px solid #83b4cd;
        }
    }
}
@media only screen and (min-width: 2560px) {
    .single_product_layout {
        .banner_layout {
            .banner_description {
                margin-top: 0;

                p {
                    // bottom: 20rem;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .single_product_layout {
        .banner_layout {
            .single_product_image_banner {
                object-fit: none;
                height: 70vh;
            }
            .banner_description {
                margin-top: 0rem;
                position: absolute;
                bottom: 30%;
                width: 80%;
                font-weight: 700;
                font-size: 32px;
                line-height: 39px;
                color: #ffffff;
                p {
                    font-size: 32px;
                    // bottom: -10rem;
                    top: 0px;
                }
            }
        }
        .single_product_description {
            margin-top: 0;
            // padding-top: 38rem;

            .single_product_title {
                font-size: 34px;
            }
            .product_text {
                width: 90%;
            }
            .single_product_smaller_title {
                margin-top: 2rem;
            }
            .partneretTane {
                img {
                }
            }
            .openPDFparagraph {
                i {
                }
            }
        }
        .galleria {
            .alleria_title {
                font-size: 28px;
            }
            .galleria_image_number {
                font-size: 28px;
                padding-left: 0;
            }
            .slick-slider {
                .slick-prev {
                    top: -11%;
                    left: 57% !important;

                    &::before {
                    }
                }
                .slick-next {
                    top: -11%;
                    right: 19.5% !important;
                    &::before {
                        content: ">";
                    }
                }
                .slick-list {
                    .slick-track {
                        .slick-slide {
                            div {
                                .single_project_slider {
                                    margin: auto;
                                    // max-height: 500px;
                                    min-height: 240px;
                                    object-fit: contain;
                                    background: no-repeat;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .partneretTane {
        text-align: center;

        img {
            object-fit: contain;
            width: 85px;
            margin: 10px 10px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}
