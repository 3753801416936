.allProjects {
    padding-top: 6rem;
    height: auto;
    align-items: center;
    .tekstiProjekteveTengjashme {
        .titulliProjekteve {
            margin-bottom: 0;
            color: #232d58;
            font-size: 40px;
            font-weight: 500;
            text-align: initial;
            line-height: 55px;
        }
        .filter {
            font-weight: 300;
            font-size: 20px;
            margin-top: 1rem;
            button {
                position: relative;
                border: none;
                background-color: transparent;
                font-family: "Roboto-Regular";

                .filter_icon {
                    font-size: 20px;
                    margin: auto;
                }
            }
        }
        .showFilters {
            height: 3rem;
            background-color: transparent;
            min-height: 23px;
            animation: fadeIn 0.2s ease-in both;
            @keyframes fadeIn {
                from {
                    height: 0rem;
                    opacity: 0;
                    transform: translate3d(0, -20%, 0);
                }
                to {
                    height: 1.9rem;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }

            button {
                padding-right: 1rem;
                padding-bottom: 1rem;
                margin-top: 0.5rem;
                // animation: fadeIn 1s ease-in both;
                background-color: transparent;
                font-family: "Roboto-Regular";
                z-index: 99;
                color: #232d58;
                font-size: 18px;
                text-align: center;
                letter-spacing: 0.01em;
                border: none;
            }
        }
        .hideFilters {
            animation: fadeOut 0.2s ease-in both;
        }
        @keyframes fadeOut {
            from {
                height: 1.9rem;
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
            to {
                height: 0rem;
                opacity: 0;
                transform: translate3d(0, -20%, 0);
            }
        }
        .hideFilters {
            button {
                animation: fadeOut 1s ease-in both;
                z-index: 99;
                color: #232d58;
                font-size: 18px;
                text-align: center;
                letter-spacing: 0.01em;
                border: none;
            }
        }
        .numrimiProjekteve {
            margin-top: 1rem;
            font-weight: 300;
            font-size: 20px;
            text-align: right;
            color: #222222;
        }
    }
    .vijaNdarese {
        border: 1px solid #d2d2d2;
        margin: 0;
    }
    .pershkrimiProjekteveTeRealizuara {
        margin: 0 auto;
        text-align: center;
        width: 877px;
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #232d58;
    }
    .singleCard {
        // width: 48%;
        position: relative;
        margin: 15px auto;
        color: white;
        .image_info {
            width: 96.4%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            color: #ffffff;
            background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%);
            mix-blend-mode: normal;
            height: 144px;
            position: absolute;
            bottom: 0;
            .cardTitle {
                margin-left: 5%;
                text-align: initial;
                font-weight: 600;
                font-size: 40px;
                line-height: 40px;
                margin-bottom: 0px !important;
            }

            .cardText {
                font-weight: 500;
                font-size: 20px;
                top: 60%;
                margin-left: 5%;
                text-align: initial;
                .locationMarker {
                    padding-right: 5px;
                }
            }
        }
    }

    .pagination {
        margin-top: 1vh;
        margin-bottom: 1vh;
        display: inline-flex;
        justify-content: center;
        align-items: center;        
        overflow: hidden;
        border-radius: 0;
    }

    .pagination li a,
    .pagination li:first-child a,
    .pagination li:last-child a {
        border: none;
        height: 45px;
        width: 45px;
        line-height: 35px;
        border-radius: 50%;
        background-color: transparent;
        color: #232d58;
        font-size: 20px;
        font-weight: 700;
        position: relative;
        overflow: hidden;
        letter-spacing: 1px;
        text-transform: uppercase;
        z-index: 1;
        margin: 0 8px 0 0;
        transition: all 0.4s ease 0s;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination .previous,
    .pagination .next {
        a {
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .pagination li a:before,
    .pagination li:first-child a:before,
    .pagination li:last-child a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #232d58;
        border-radius: 50%;
        opacity: 0;
        transform: scaleY(0);
        transition: all 0.3s ease 0s;
    }

    .pagination li a:hover:before,
    .pagination li.active a:before {
        transform: scaleY(1);
        opacity: 1;
    }

    .pagination li:last-child a {
        margin-right: 0;
    }

    .pagination li.active a,
    .pagination li a:hover,
    .pagination li.active a:hover {
        background-color: transparent;
        color: #232d58;
        border: none;
    }
    @media only screen and (max-width: 480px) {
        .pagination {
            // display: block;
        }
        .pagination li {
            display: inline-block;
            margin-bottom: 20px;
        }
    }
}
@media only screen and (max-width: 800px) {
    .allProjects {
        padding-top: 5.5em;
        .tekstiProjekteveTengjashme {
            .titulliProjekteve {
                font-size: 24px;
                line-height: 24px;
            }
            .filter {
                margin-top: 1.7rem;
                margin-bottom: 0.5rem;
                font-weight: 300;
                font-size: 20px;
                .filter_icon {
                    font-size: 15px;
                }
            }
            .numrimiProjekteve {
                margin-bottom: 0.5rem;

                margin-top: 2rem;
                font-size: 15px;
                line-height: 20px;
            }
            .showFilters {
                height: 5.3rem !important;
                justify-content: space-between;
                @keyframes fadeIn {
                    from {
                        height: 0rem;
                        opacity: 0;
                        transform: translate3d(0, -20%, 0);
                    }
                    to {
                        height: 5.3rem;
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                }
                button {
                    font-size: 16px;
                    padding-bottom: 0;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .hideFilters {
                animation: fadeOut 0.2s ease-in both;
            }
            @keyframes fadeOut {
                from {
                    height: 5.3rem;
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
                to {
                    height: 0rem;
                    opacity: 0;
                    transform: translate3d(0, -20%, 0);
                }
            }
        }
        .singleCard {
            .image_info {
                width: 94%;
                .cardTitle {
                    top: 44%;
                    font-size: 23px;
                    line-height: 23px;
                }
                .cardText {
                    font-size: 18px;
                    top: 64%;
                }
            }
        }
    }
}
