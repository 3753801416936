.standardi-xhamit-layout {
    padding-top: 6em;
    .justify-cards {
        justify-content: space-between;
        .single_standard {
            border: none;
            .card-img-top {
                opacity: 0.6;
            }
            .card-body {
                text-decoration: none !important;

                .Card-Title {
                    margin-bottom: 0;
                    text-align: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    color: #1a2246;
                    text-decoration: none !important;
                }
                a {
                    font-family: "Roboto-Light";
                    // color: #ffffff;
                    color: #182354;
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                }
                a:hover {
                    text-decoration: underline;
                }
            }

            @media (min-width: 991px) {
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    .card-img-top {
                        animation-name: fade-in;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
                &:not(:hover) {
                    .card-img-top {
                        animation: fade-out 0.5s;
                        animation-duration: 0.5s;
                        animation-fill-mode: forwards;
                    }
                }
            }
        }
    }
      .single_product_layout {
        margin: auto 0;
            .standardi-xhamit_title {
                font-family: "Roboto-Medium";
                font-weight: 500;
                font-size: 34px;
                line-height: 35px;
                color: #232d58;
            }
            .standardi-xhamit_description_main_page {
                font-family: "Roboto-Regular" !important;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                color: #232d58;
                .single_product_smaller_title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 26px;
                    color: #232d58;
                }
            }
          
        }
}
