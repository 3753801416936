@font-face {
  font-family: "RobotoCondensedRegular";
  src: local("AirbnbCerealLight"), url("./fonts/RobotoCondensed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoCondensedBold";
  src: local("AirbnbCerealLight"), url("./fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("AirbnbCerealLight"), url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Light";
  src: local("AirbnbCerealLight"), url("./fontsOld/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Medium";
  src: local("AirbnbCerealLight"), url("./fontsOld/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("AirbnbCerealLight"), url("./fontsOld/Roboto-Bold.ttf") format("truetype");
}
@media only screen and (max-width: 800px) {
  .back-to-top {
    margin: 0px 10px 10px 0px !important;
  }
}
.back-to-top {
  z-index: 9;
  .ScrollToTop {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    display: flex;
    background-color: #1a2246;
    .backToTopIcon {
      margin: auto;
      color: white;
      font-size: 27px;
    }
  }
}
.App {
  /* text-align: center; */
  font-family: "RobotoCondensedRegular";
  /* overflow-x: hidden; */
}
.partneretTane {
  text-align: center !important;
  img {
    margin: 1% 1% !important;
    width: 132px !important;
    height: 61px !important;
  }
}
@media only screen and (max-width: 800px) {
  .partneretTane {
    text-align: center !important;
    img {
      object-fit: contain !important;
      width: 80px !important;
      // margin: auto;
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
