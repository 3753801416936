.about_us_layout {
    .about_us_company_image {
        height: 70vh;
        object-fit: cover;
        width: 100%;
    }
    .about_us_content {
        .about_us_page_title {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 55px;
            color: #222222;
        }
        .company_description {
            font-family: "Roboto-Light";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            color: #222222;
        }
        .about_us_inside_company_image {
            width: 100%;
        }
    }
    .partneretTane {
        text-align: center;
        img {
            margin: 1% 1%;
            width: 136px;
            height: 61px;
        }
    }
}
@media only screen and (max-width: 800px) {
    .about_us_layout {
        .about_us_company_image {
            padding-top: 3.6rem;
            line-height: 0;
            object-fit: cover;
            height: 30rem;
            object-position: 0 0;
        }
        .about_us_content {
            .about_us_page_title {
                font-size: 32px;
                margin-top: 1rem !important;

            }
            .company_description {
                font-size: 16px;
                line-height: 25px;
            }
            .about_us_inside_company_image {
            }
        }
        .partneretTane {
            img {
                object-fit: contain;
                width: 85px;
                // margin: auto;
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
}
