.katallogjet_layout {
  padding-top: 7rem;

  @keyframes fade-in {
    0% {
      transform: translateY(-0px);
      filter: grayscale(80%);
      opacity: 0.6;
    }
    25% {
      transform: translateY(-3px);
      filter: grayscale(60%);
      opacity: 0.7;
    }
    50% {
      transform: translateY(-6px);
      filter: grayscale(40%);
      opacity: 0.8;
    }
    75% {
      transform: translateY(-9px);
      filter: grayscale(20%);
      opacity: 0.9;
    }
    100% {
      transform: translateY(-12px);
      filter: grayscale(0%);
      opacity: 1;
    }
  }
  @keyframes fade-out {
    0% {
      transform: translateY(-12px);
      filter: grayscale(0%);
      opacity: 1;
    }

    25% {
      transform: translateY(-9px);
      filter: grayscale(20%);
      opacity: 0.9;
    }

    50% {
      transform: translateY(-6px);
      filter: grayscale(40%);
      opacity: 0.8;
    }

    75% {
      transform: translateY(-3px);
      filter: grayscale(60%);
      opacity: 0.7;
    }

    100% {
      transform: translateY(-0px);
      filter: grayscale(80%);
      opacity: 0.6;
    }
  }
  .justify-cards {
    justify-content: space-between;
    .single_katallog {
      border: none;
      .card-img-top {
        opacity: 0.6;
      }
      .card-body {
        text-decoration: none !important;

        .Card-Title {
          margin-bottom: 0;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          color: #1a2246;
          text-decoration: none !important;
        }
        a {
          font-family: "Roboto-Light";
          // color: #ffffff;
          color: #182354;
          text-decoration: none;
          display: flex;
          flex-direction: column;
        }
        a:hover {
          text-decoration: underline;
        }
      }

      @media (min-width: 991px) {
        &:hover {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          .card-img-top {
            animation-name: fade-in;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:not(:hover) {
          .card-img-top {
            animation: fade-out 0.5s;
            animation-duration: 0.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}
// .card{
//   border-radius: 4px;
//   background: #fff;

@media (max-width: 990px) {
  .katallogjet_layout {
    .justify-cards {
      justify-content: center;
      .single_katallog {
        .card-img-top {
          opacity: 1;
          filter: grayscale(50%);
        }
        .card-body {
          .Card-Title {
          }
        }

        &:hover {
          .card-img-top {
            animation: none;
          }
        }
        &:not(:hover) {
          .card-img-top {
          }
        }
      }
    }
  }
}
