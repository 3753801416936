.footer {
    .footer_img {
        height: 70vh;
        .gradienti {
            position: absolute;
            width: 100%;
            height: 259px;
            background: linear-gradient(
                180deg,
                #ffffff -15.19%,
                rgba(255, 255, 255, 0.6) 55.21%,
                rgba(255, 255, 255, 0) 100%
            );
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .footer_layout_info {
        .vija_ndarese_footer {
            height: 1px;
            color: #d2d2d2;
        }
        // background: linear-gradient(83.17deg, #090e21 -49.91%, #182354 62.75%); // kjo ta kthen footerin teget
        background: white; // kjo ta kthen footerin teget
        color: #182354;
        // color: white;
        text-align: left;
        padding: 40px 5px 40px 5px;
        .footer_box {
            padding: 20px;
            .footer_info_title {
                text-decoration: none;
                font-family: "RobotoCondensedBold";
                padding-bottom: 10px;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                // color: #6e7594;
                color: #182354;
            }
            .footer_text {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                // color: #ffffff;
                color: #182354;

                a {
                    font-family: "Roboto-Light";
                    // color: #ffffff;
                    color: #182354;
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                    
                }
                a:hover{
                    text-decoration: underline;
                }
            }
        }

        .footer_bottom {
            padding-top: 45px;
            padding: 20px;
            font-family: "Roboto-Light";

            .privacy {
                display: flex;
                justify-content: flex-end;
                .line_seperator {
                    width: 1px;
                    height: 2vh;
                    border-radius: 2px;
                    // background-color: white;
                    background-color: #182354;
                    margin: auto 15px;
                }
                a {
                    // color: #ffffff;
                    color: #182354;
                    text-decoration: none;
                }
            }
        }
    }
}
@media only screen and (max-width: 800px) {
    .footer {
        .footer_layout_info {
            .footer_bottom {
                .privacy {
                    flex-direction: column;
                    justify-content: flex-start;
                    .line_seperator {
                        display: none;
                    }
                }
            }
        }
    }
}
