.header_layout {
	position: absolute;
	z-index: 9;
	.header_background {
		padding-top: 11px !important ;
		background-color: transparent;
	}
	@keyframes header_fade_in_top {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 1;
		}
	}
	.navbar-nav {
		&:hover {
			transition: 0.5s;

			.nav-link {
				position: relative;
				color: #1a2246 !important;
				z-index: 99999999;
			}
			& > .navbar-collapse {
				> .navbar-brand {
					.blueCompanyLogo {
						display: block;
						position: relative;
						z-index: 9999999999999;
					}
				}
			}
			& ~ .languages {
				.languages_options {
					.languagesButtons {
						z-index: 99999999;
						color: #1a2246 !important;
					}
				}
				.b2bButton {
					z-index: 99999999;
					border-color: #1a2246;
					color: #1a2246 !important;
				}
			}
		}
	}
	.projects_without_submenu_links {
		display: none;
	}
	#visable-links {
		&:hover {
			transition-delay: 3s;
			.projects_without_submenu_links {
				animation-name: header_fade_in_top;
				animation-duration: 0.1s;
				animation-fill-mode: forwards;
				display: flex !important;
				position: absolute;
				z-index: 999;
				min-width: 100%;
				top: 0;
				left: 0;

				.blueCompanyLogo {
					position: absolute;
					max-width: 12.8%;
					top: 2.3vh;
				}
			}
			.blueCompanyLogo {
				animation-name: none;
				animation-duration: 0s;
			}
			.projects_submenu_links {
				animation-name: header_fade_in_top;
				animation-duration: 0.2s;
				animation-fill-mode: forwards;
				display: flex !important;
				position: absolute;
				z-index: 999;
				min-width: 100%;
				top: 0;
				left: 0;
				.onHoverInlineStyle {
					.blueCompanyLogo {
						animation-name: header_fade_in_top;
						animation-duration: 0s;
						position: absolute;
						max-width: 194.4px;
						max-height: 47.927px;
						top: 2.2vh;
					}
				}
				ul {
					// padding-top: 5rem;
					padding-bottom: 2rem;
					.subsublink {
						margin-left: 28%;
					}
					.subsublink-whatweoffer {
						margin-left: 46%;
					}
					.subsublink-forPublic {
						margin-left: 64%;
					}
				}
			}
		}
		.about_us_submenu_links {
			transition: 2.5s;

			display: none;
			position: absolute;
			z-index: 1;
			background-color: white;
			.onHoverInlineStyle {
				.blueCompanyLogo {
					animation-name: header_fade_in_top;
					animation-duration: 0s;
					position: absolute;
					max-width: 194.4px;
					max-height: 47.927px;
					top: 1.7vh;
				}
			}
			ul {
				margin-top: 3rem;
				margin-bottom: 1.5rem;
				a {
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 45px;
					color: #1a2246;
					text-decoration: none;
				}
			}
		}
		.projects_submenu_links {
			display: none;
			position: absolute;
			z-index: 1;
			background-color: white;
			.onHoverInlineStyle {
				.blueCompanyLogo {
					animation-name: header_fade_in_top;
					animation-duration: 0s;
					position: absolute;
					max-width: 194.4px;
					max-height: 47.927px;
					top: 1.7vh;
				}
			}
			ul {
				transition: height 0.3s ease-in-out;

				margin-top: 3rem;
				// margin-bottom: 1.5rem;
				a {
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 45px;
					color: #1a2246;
					text-decoration: none;
				}
			}
		}
	}

	.navbar-brand {
		width: 20%;
		margin-right: 0;
		img {
			width: 80%;
		}
		.whiteCompanyLogo {
			max-width: 75%;
		}
	}

	.navbar-light {
		.navbar-collapse {
			.navbar-nav {
			}
		}
		.navbar-nav .nav-link {
			color: white;
		}
		.navbar-toggler {
			// border-color: white;
			// color: white;
			&:focus {
				color: rgba(255, 255, 255, 0);
				border-color: rgba(255, 255, 255, 0);
				box-shadow: 0 0 0 0rem;
			}
			.animated-icon3 {
				width: 30px;
				height: 20px;
				position: relative;
				margin: 0px;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.5s ease-in-out;
				-moz-transition: 0.5s ease-in-out;
				-o-transition: 0.5s ease-in-out;
				transition: 0.5s ease-in-out;
				cursor: pointer;
				span {
					display: block;
					position: absolute;
					height: 3px;
					width: 100%;
					border-radius: 9px;
					opacity: 1;
					left: 0;
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
					-webkit-transition: 0.25s ease-in-out;
					-moz-transition: 0.25s ease-in-out;
					-o-transition: 0.25s ease-in-out;
					transition: 0.25s ease-in-out;
					background: #f3e5f5;
				}

				span:nth-child(1) {
					top: 0px;
					-webkit-transform-origin: left center;
					-moz-transform-origin: left center;
					-o-transform-origin: left center;
					transform-origin: left center;
				}

				span:nth-child(2) {
					top: 10px;
					-webkit-transform-origin: left center;
					-moz-transform-origin: left center;
					-o-transform-origin: left center;
					transform-origin: left center;
				}

				span:nth-child(3) {
					top: 20px;
					-webkit-transform-origin: left center;
					-moz-transform-origin: left center;
					-o-transform-origin: left center;
					transform-origin: left center;
				}
			}
			.open span:nth-child(1) {
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
				top: 0px;
				left: 8px;
			}

			.open span:nth-child(2) {
				width: 0%;
				opacity: 0;
			}

			.open span:nth-child(3) {
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
				top: 21px;
				left: 8px;
			}
		}
	}
	section:hover .post {
		background-color: white;
	}

	a {
		font-family: "RobotoCondensedRegular";
	}
	.nav-link {
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 23px;
		color: white;
	}
	.languages {
		display: flex;

		.languages_options {
			display: flex;
			align-items: center;
			.languagesButtons {
				color: white;
				font-size: large;
				font-weight: 500;
				margin: 0 10px;
			}
			.selectedLang {
				font-weight: 700;
			}
		}
		.b2bButton {
			line-height: 1;
			cursor: pointer;
			width: auto;
			border: 2px solid white;
			background-color: transparent;
			border-radius: 2px;
			border-color: white;
			color: white;
			font-size: x-large;
		}
	}
}
.responsive_mobile_header {
	display: none;
	opacity: 0;
}
@media only screen and (max-width: 800px) {
	.header_layout {
		position: absolute;
		background: linear-gradient(0deg, #1a2246, #1a2246);
		z-index: 12;
		.navbar-brand {
			max-width: 200%;
			.whiteCompanyLogo {
				max-width: 190% !important;
			}
			img {
				width: 190%;
			}
		}
	}
	.responsive_mobile_header {
		display: block;
		opacity: 1;
		.hamburger-button {
			margin-left: 90%;
			margin-top: 3.5%;
			outline: none;
			border: none;
			cursor: pointer;
			width: 1.3em;
			height: 2.2em;
			position: absolute;
			z-index: 12;
			background-color: transparent;
			background-image: url(https://res.cloudinary.com/dnpyq0ocz/image/upload/v1588441892/icon-hamburger.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			-webkit-tap-highlight-color: transparent;

			&.open {
				background-image: url(https://res.cloudinary.com/dnpyq0ocz/image/upload/v1588441892/icon-hamburger-close.svg);
			}
		}

		.panel {
			max-width: 100%;
			background-color: white;
			background-size: 117px 188px;
			transition: all 0.4s cubic-bezier(0.42, 0, 0.34, 1.01);
			position: fixed;
			z-index: 5;
			right: -100%;
			top: 0;
			height: 100%;
			width: 100%;
			visibility: hidden;
			opacity: 0;
			padding: 42px 21px 0em 22px;
			display: flex;

			&.open {
				z-index: 11;
				visibility: visible;
				width: 100%;
				right: 0;
				opacity: 1;
			}
		}

		ul {
			justify-content: space-evenly;
			padding-left: 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			width: 100%;
			margin-top: 28px;
			li {
				list-style: none;
				font-size: 1em;
				text-align: left;

				& + li {
					margin-top: 1em;
				}
				.mobile_menu_titles {
					color: #1a2246;
					font-style: normal;
					font-weight: 900;
					font-size: 16px;
					line-height: 1px;
				}
				.other_mobile_links {
					margin-top: 0.2em;
					display: flex;
					flex-direction: column;
					a {
						font-family: "Roboto-Light";
						color: #1a2246;
					}
				}
				a {
					text-decoration: none;
					color: #1a2246;

					&:hover {
						color: grey;
					}
				}
			}
		}
		.vija_ndarese_header {
			width: 100%;
			border: 1px solid #d2d2d2;
		}
		.languages {
			display: flex;
			width: 100%;
			justify-content: space-between;
			.languages_options {
				display: flex;
				align-items: center;
				.languagesButtons {
					color: #1a2246;
					font-size: large;
					font-weight: 500;
					margin: 0 10px;
				}
				.selectedLang {
					font-weight: 700;
				}
			}
			.b2bButton {
				line-height: 1;
				cursor: pointer;
				width: auto;
				border: 2px solid white;
				background-color: transparent;
				border-radius: 2px;
				border-color: #1a2246;
				color: #1a2246;
				font-size: x-large;
			}
		}
	}
}
