.our_staff_layout {
    .our_staff_company_image {
        height: 70vh;
        object-fit: cover;
        width: 100%;
    }
    .our_staff_content {
        .our_staff_page_title {
            font-family: "Roboto-Regular";
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 55px;
            
            color: #232d58;
        }
        .staff-name {
            font-family: "RobotoCondensedBold";
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: -5px;
            color: #232d58;
            font-size: 24px;
            font-weight: 500;
  
        }
        .staff_image{
            max-width: 196px;
            max-height: 244px;
        }
        .company_description{
            line-height: 20px;
            font-size: 16px;
            // margin-top: 8px;
        }
        .our_staff_inside_company_image {
            width: 100%;
        }
    }
  
}
@media only screen and (max-width: 800px) {
    .our_staff_layout {
        .our_staff_company_image {
            padding-top: 3.6rem;
            line-height: 0;
            object-fit: cover;
            height: 30rem;
            object-position: 0 0;
        }
        .our_staff_content {
            .our_staff_page_title {
                padding-top: 15px ;
                font-size: 25px;
                margin-top: 0rem !important;
                line-height: normal;
            }
            .staff-name {
                font-size: 22px;
                line-height: 25px;
            }
            .company_description{
                line-height: 16px;
                font-size: 15px;
                margin-top: 8px;
            }
            .staff_image{
                max-width: 80%;
                max-height: 80%;
            }
        }
    }
}
