.PergjegjsiaSociale_layout {
    .about_us_content {
        .about_us_page_title {
            font-family: "Roboto-Medium";
            font-weight: 500;
            font-size: 44px;
            line-height: 55px;
            color: #232d58;
        }
        .company_description {
            font-family: "Roboto-Regular" !important;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            color: #232d58;
        }
        ul {
            li {
                font-family: "Roboto-Light";
                // font-family: "Roboto";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                color: #232d58;
            }
        }
    }
    .sponzorizime {
        .single_product_layout {
            .SocialResponsibility_title {
                font-family: "Roboto-Medium";
                font-weight: 500;
                font-size: 44px;
                line-height: 55px;
                color: #232d58;
            }
            .SocialResponsibility_description_main_page {
                font-family: "Roboto-Regular" !important;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                color: #232d58;
                .single_product_smaller_title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 26px;
                    color: #232d58;
                }
            }
            .single_product_button {
                background: linear-gradient(46.81deg, #2e55ba -11%, #1a2246 168.73%);
                border-radius: 2px;
                color: white;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 125px;
                cursor: pointer;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.01em;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .PergjegjsiaSociale_layout {
        .about_us_company_image {
            padding-top: 3.6rem;
            line-height: 0;
            object-fit: cover;
            height: 30rem;
            object-position: auto;
        }
        .sponzorizime {
            .single_product_layout {
                .SocialResponsibility_title {
                    font-size: 25px;
                    line-height: 28px;
                    margin-top: 1rem !important;
                }
                .SocialResponsibility_description_main_page {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }
}
