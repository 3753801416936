// @font-face {
//     font-family: "Roboto-Regular";
//     src: local("AirbnbCerealLight"), url("../../fonts/Roboto-Regular.ttf") format("truetype");
//   }
.porosia {
    padding-top: 5em;

    .description_window_text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #1a2246;
        position: absolute;
    }

    background: linear-gradient(74.93deg, #d5dbe6 15.12%, #f1f1f1 62.21%);
    mix-blend-mode: normal;

    .listing {
        .orders_listing {
            background: rgba(255, 255, 255, 0.5);
            box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            .selected_order {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 30px;
                color: #1a2246;
            }
        }
    }
    .colorSelect {
        .leftContent {
            .paragrafi {
                font-family: Roboto-Regular;
            }
            .ngjyrat_e_dritareve {
                justify-content: space-evenly;
                .color_selector {
                    height: 5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .color_desc {
                    color: transparent;
                }
                .color_desc_white_antracid {
                    color: transparent;
                }

                .whiteColor {
                    margin-bottom: 10px;
                    width: 58px;
                    height: 56px;
                    border-radius: 7px;
                    background-color: white;
                    position: absolute;
                    appearance: none;
                    cursor: pointer;

                    /* When the radio button is checked, add a blue background */
                    &:checked ~ .checkmark {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        // position: absolute;
                        // left: 6.1rem;
                        // top: 37.5rem;
                        border: 2px solid #1a2246;
                        border-radius: 6px;
                        height: 6.438rem;
                        min-width: 86px;

                        .color_desc {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 30px;
                            color: #1a2246;
                            margin: inherit;
                        }
                    }
                    &:checked ~ .checkmarkD2k {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        // position: absolute;
                        // left: 6.1rem;
                        // top: 37.5rem;
                        border: 2px solid #1a2246;
                        border-radius: 6px;
                        height: 6.438rem;
                        min-width: 86px;

                        .color_desc {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 30px;
                            color: #1a2246;
                            margin: inherit;
                        }

                        //Anton
                        // border: 2px solid #1a2246;
                        // border-radius: 6px;
                        // height: 20rem;
                        // width: 26rem;
                        // cursor: pointer;
                        // position: absolute;
                    }
                }

                .brownDiv {
                    margin-bottom: 10px;
                    width: 58px;
                    height: 56px;
                    border-radius: 7px;
                    background-color: #8e4f0c;
                    box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
                    position: absolute;
                    appearance: none;
                    cursor: pointer;

                    &:checked ~ .checkmark {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        // position: absolute;
                        // left: 14.05rem;
                        // top: 37.5rem;
                        border: 2px solid #1a2246;
                        border-radius: 6px;
                        height: 6.438rem;
                        min-width: 86px;

                        .color_desc {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 30px;
                            color: #1a2246;
                            margin: inherit;
                        }
                    }
                }

                .antracidDiv {
                    margin-bottom: 10px;
                    background: #343434;
                    box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
                    border-radius: 6px;
                    width: 56px;
                    height: 56px;
                    border-radius: 7px;
                    position: absolute;
                    appearance: none;
                    cursor: pointer;

                    &:checked ~ .checkmark {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        // position: absolute;
                        // left: 14.05rem;
                        // top: 37.5rem;
                        border: 2px solid #1a2246;
                        border-radius: 6px;
                        height: 6.438rem;
                        min-width: 86px;

                        .color_desc {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 30px;
                            color: #1a2246;
                            margin: inherit;
                        }
                    }
                }

                .white_antracid_divv {
                    margin-bottom: 10px;
                    height: 50px;
                    border-radius: 7px;
                    width: 50px;
                    background-position: center;
                    position: absolute;
                }
                .white_antracid_div {
                    height: 50px;
                    width: 50px;
                    position: absolute;
                    appearance: none;
                    cursor: pointer;

                    &:checked ~ .checkmark {
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        border: 2px solid #1a2246;
                        border-radius: 6px;
                        height: 6.638rem;
                        width: 90px;
                        .color_desc_white_antracid {
                            padding: 0 10px;
                            width: fit-content;
                            position: relative;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 18px;
                            text-align: center;
                            line-height: 71px;
                            color: #1a2246;
                            margin-bottom: -1.1rem;
                        }
                    }
                }
            }

            .profili {
                max-width: 200px;
                min-width: 200px;
                object-fit: contain;
            }

            .ngjyrat {
                .ngjyra1 {
                    display: flex;

                    .ngjyra1Content {
                        margin-top: 15px;
                    }

                    .ngjyra2Content {
                        margin-top: 15px;

                        .brownDiv {
                            width: 56px;
                            height: 56px;
                            border-radius: 7px;
                            background-color: #8e4f0c;
                            z-index: 9;
                            position: relative;
                            appearance: none;
                            cursor: pointer;
                        }

                        /* When the radio button is checked, add a blue background */
                        input:checked ~ .checkmark {
                            border-radius: 6px;

                            position: absolute;
                            // top: 559px;
                            // left: 97px;
                            top: 73.3%;
                            left: 11%;
                            border: 2px solid #1a2246;
                            height: 85px;
                            width: 86px;
                        }
                    }

                    .ngjyra3Content {
                        margin-top: 15px;

                        .blackDiv {
                            width: 56px;
                            height: 56px;
                            border-radius: 7px;
                            background-color: #343434;
                        }
                    }

                    .ngjyra4Content {
                        margin-top: 15px;
                        // padding: 30px;

                        .Rectangle507 {
                            border-radius: 7px;
                            background-color: #8e4f0c;
                        }
                    }

                    .ngjyra5Content {
                        margin-top: 15px;
                        // padding: 30px;

                        .Rectangle508 {
                            border-radius: 7px;
                            background-color: #343434;
                        }
                    }
                }
            }
        }

        .rightContent {
            display: flex;
            justify-content: flex-end;

            .dritare1 {
                max-height: 654px;
                object-fit: contain;
                // max-width: 319px;
                // object-fit: contain;
                // min-width: 319px;
            }
        }

        .title {
            font-family: "Roboto-Medium";
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 45px;
            color: #1a2246;
            padding-top: 5%;
        }

        .imgContent {
            display: flex;
            align-items: center;
            max-width: fit-content;
            flex-direction: column;

            p {
                font-family: "Roboto-Regular";
                color: #1a2246;
                font-weight: 500;
            }
        }
    }

    .vijaNdarese {
        color: white;
        height: 2px;
    }
    .vijaNdareseSasia {
        color: white;
        height: 2px;
    }
    .vijaNdareseRoleta {
        color: white;
        height: 2px;
    }

    .dimensionetSasia {
        .inputFields {
            align-items: center;
            justify-content: space-between;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type="number"] {
                -moz-appearance: textfield;
            }
            .inputContent {
                display: flex;
                // align-items: center;
                vertical-align: middle;

                input {
                    width: 80%;
                    height: 33px;
                    border: none;
                    border-radius: 5px;
                    padding: 0 10px 0 10px;
                    // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                }

                span {
                    margin: auto;
                    font-family: "Roboto-Regular";
                    padding-left: 10px;
                    vertical-align: middle;
                }
            }

            .inputContent1 {
                display: flex;
                // align-items: center;
                vertical-align: middle;

                input {
                    padding: 0px 10px 0px 10px;
                    width: 80%;
                    height: 33px;
                    border: none;
                    border-radius: 5px;
                    // box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
                }

                span {
                    padding-left: 10px;
                    vertical-align: middle;
                }
            }
        }
    }

    .windowTypes {
        .window_selector {
            min-height: 15rem;
            display: inline-grid;

            .window_radio_button {
                appearance: none;
                &:checked ~ .checkmarkD2k {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 20rem;
                    width: 28rem;
                    position: absolute;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
                &:checked ~ .checkmark {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 20rem;
                    width: 100%;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }

                &:checked ~ .window_bigger_option_checkmark {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 26rem;
                    width: 13rem;
                    position: absolute;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
                &:checked ~ .window_bigger_option_checkmark2 {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 30rem;
                    width: 15rem;
                    position: absolute;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }

                &:checked ~ .two_dors_window_option_checkmark {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 26rem;
                    width: 24rem;
                    position: absolute;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
                &:checked ~ .two_dors_window_option_checkmark2 {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 32rem;
                    width: 28rem;
                    position: absolute;
                    cursor: pointer;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
            }

            .displace_windows_evenly {
                justify-content: space-between;
                flex-wrap: wrap;
                max-width: 100%;
                .paretn_window_type {
                    display: flex;
                    // align-items: center;
                    justify-content: center;
                    min-height: 29rem;

                    .description_window_type_text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        color: #1a2246;
                    }
                }

                .paretn_bigger_window_type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 27rem;
                    margin-top: -6.5rem !important;
                    min-width: 11rem;

                    .description_window_type_text {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                        color: #1a2246;
                    }
                }

                .tipet_e_dritareve {
                    justify-content: center;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 20px;
                    .dere_dy_kaheshe_option {
                        &:not(.test1) {
                            max-width: 26rem;
                        }
                        max-width: 10rem;
                        min-height: 28rem;
                    }
                    .dritare_nje_kaheshe_option {
                        // max-width: 180px;
                        min-height: 15rem;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }

                    .dere_dy_kaheshe_option1 {
                        max-width: 26rem;
                        min-height: 21rem;
                        margin-bottom: 10px;

                        cursor: pointer;
                    }
                    .dere_dy_kaheshe_option2 {
                        max-width: 26rem;
                        min-height: 21rem;
                        margin-bottom: 10px;

                        cursor: pointer;
                    }

                    .dere_nje_kaheshe_option {
                        // max-width: 20rem;
                        min-width: 10rem;
                        max-height: 25rem;
                        min-height: 25rem;
                        margin-bottom: 10px;
                        cursor: pointer;
                    }

                    .blind_veneciane_option {
                        max-width: 100px;
                        max-height: 229px;
                        margin: auto;
                        // margin-bottom: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .roletat {
        .blinds_selector {
            min-height: 15rem;
            display: inline-grid;

            .blinds_radio_button {
                appearance: none;

                &:checked ~ .checkmark {
                    border: 2px solid #1a2246;
                    border-radius: 6px;
                    height: 20rem;
                    width: 10rem;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
            }

            .roletat_mbi {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 20rem;

                .description_blinds_text {
                    margin: 0 auto;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    color: #1a2246;
                }
            }

            .blinds_over {
                justify-content: center;
                position: absolute;
                display: flex;
                flex-direction: column;

                .blind_option_img {
                    max-width: 58px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .blind_side_option {
                    max-width: 100px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .blind_veneciane_option {
                    max-width: 100px;
                    max-height: 229px;
                    height: 229px;
                    margin: auto;
                    // margin-bottom: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .add_order {
        margin: 0 auto;
        text-align: center;

        .add_new_order {
            background: #1a2246;
            box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 43px;
            letter-spacing: 0.02em;
            color: #ffffff;

            span {
                margin: 0px 20px 0px 20px;
            }
        }
    }

    .your_data {
        .informativ_text_for_user {
            margin-bottom: 2.5rem;
            font-family: "Roboto-Medium";
            font-size: 38px;
            font-style: normal;
            font-weight: 500;
            line-height: 45px;
            color: #1a2246;
        }

        .personal_data_input_fields {
            text-align: center;

            .inputContent {
                .name_and_surname {
                    font-family: "Roboto-Regular";
                    position: absolute;
                    pointer-events: none;
                    margin-left: -25rem;
                    transition: 0.5s;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 40px;
                    letter-spacing: 0.02em;
                    color: #222222;
                }

                input {
                    width: 100%;
                    height: 40px;
                    padding-left: 7px;
                    border: none;
                    background: #ffffff;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    border-radius: 6px;
                }

                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type="number"] {
                    -moz-appearance: textfield;
                }

                input:valid ~ span,
                input:focus ~ span {
                    transform: translateX(-12px) translateY(-33.2px);
                }
            }
        }

        .send_order_and_button {
            text-align: center;

            .send_to_contact_you_text {
                font-family: "Roboto-Regular";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #1a2246;
            }

            .send_this_order {
                font-family: "Roboto-Regular";
                min-width: 232px;
                border: none;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 43px;
                text-align: center;
                letter-spacing: 0.02em;
                // text-transform: none;
                color: #ffffff;
                background: #1a2246;
                box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                min-width: 175px;

                font-weight: 600;
                font-size: 17px;
                line-height: 40px;
            }
        }
    }
    .partneretTane {
        text-align: center;

        img {
            object-fit: contain;
            width: 85px;
            margin: 10px 10px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 800px) {
    .windowTypes {
        .window_selector {
            .window_radio_button {
                &:checked ~ .checkmark {
                    height: 11rem !important;
                    width: 9rem !important;
                    position: absolute;
                }
                &:checked ~ .checkmarkD2k {
                    height: 11rem !important;
                    width: 10rem !important;

                    .color_desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 30px;
                        color: #1a2246;
                        margin: inherit;
                    }
                }
                &:checked ~ .window_bigger_option_checkmark {
                    height: 12rem !important;
                    width: 7.3rem !important;
                    margin-left: 26px !important;
                    position: absolute !important;
                    z-index: 9;
                    .color_desc {
                    }
                }
                &:checked ~ .window_bigger_option_checkmark2 {
                    height: 12rem !important;
                    width: 7.3rem !important;
                    z-index: 9;
                    .color_desc {
                    }
                }

                &:checked ~ .two_dors_window_option_checkmark {
                    height: 17rem !important;
                    width: 13rem !important;
                    position: absolute;
                    .color_desc {
                    }
                }
                &:checked ~ .two_dors_window_option_checkmark2 {
                    height: 12rem !important;
                    width: 10rem !important;
                    position: absolute;
                    .color_desc {
                    }
                }
            }

            .displace_windows_evenly {
                // margin-left: 0;
                .paretn_window_type {
                    min-height: 7rem !important;
                    .description_window_type_text {
                        font-size: 15px !important;
                    }
                }
                .responsive_costum_style {
                    margin-top: -2rem !important;
                    display: contents;
                }
                .paretn_bigger_window_type {
                    .description_window_type_text {
                        font-size: 15px !important;
                    }
                }

                .tipet_e_dritareve {
                    position: relative;
                    .dritare_nje_kaheshe_option {
                    }

                    .dere_dy_kaheshe_option {
                        margin-bottom: 0px !important;
                    }

                    .dere_nje_kaheshe_option {
                        max-height: 8rem !important;
                        min-height: 8rem !important;
                    }
                    .dere_dy_kaheshe_option1 {
                        max-height: 8rem !important;
                        min-height: 8rem !important;
                    }
                    .dere_dy_kaheshe_option2 {
                        max-height: 8rem !important;
                        min-height: 8rem !important;
                    }

                    .blind_veneciane_option {
                    }
                }
            }
        }
    }
    .your_data {
        .informativ_text_for_user {
            font-weight: 600 !important;
            font-size: 22px !important;
            line-height: 30px !important;
            margin-top: 1rem !important;
            margin-bottom: 2rem !important;
        }

        .personal_data_input_fields {
            .inputContent {
                margin-bottom: 1.8rem !important;
                .name_and_surname {
                    margin-left: -22rem !important;
                    line-height: 35px !important;
                    font-size: 14px !important;
                }

                input {
                    height: 35px !important;
                }

                input:valid ~ span,
                input:focus ~ span {
                    font-size: 11px !important;

                    transform: translateX(-5px) translateY(-28px) !important;
                }
            }
        }

        .send_order_and_button {
            text-align: center;

            .send_to_contact_you_text {
                font-style: normal;
                font-weight: 400;
                font-size: 16px !important;
                line-height: 20px !important;
                color: #1a2246;
                margin-bottom: 1rem !important;
            }

            .send_this_order {
                font-family: "Roboto-Regular";
                min-width: 232px;
                border: none;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 43px;
                text-align: center;
                letter-spacing: 0.02em;
                // text-transform: none;
                color: #ffffff;
                background: #1a2246;
                box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                // margin-bottom: 10%;
            }
        }
    }
    .vijaNdarese {
        margin-top: 3rem;
    }
}

@media only screen and (max-width: 800px) and (min-width: 600px) {
    .your_data .personal_data_input_fields .inputContent .name_and_surname {
        margin-left: -22rem !important;
        line-height: 35px !important;
    }
}

@media only screen and (max-width: 700px) {
    .porosia {
        .listing {
            .orders_listing {
                .numri_porosis {
                    height: 22%;
                    display: flex;
                    position: absolute;
                    align-items: center;
                    left: 8px;
                }
                .ndarsi_imazhit {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    img {
                        width: 45%;
                    }
                }
                .selected_order {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
        }

        .roletat {
            .blinds_selector {
                .blinds_radio_button {
                    &:checked ~ .checkmark {
                        height: 16rem;
                        .color_desc {
                        }
                    }
                }
                .roletat_mbi {
                    width: 30%;
                    .description_blinds_text {
                        font-size: 15px !important;
                    }
                }
                .roletat_veneciane {
                    width: 35%;
                }
                .blinds_over {
                    .blind_option_img {
                        max-width: 2.8rem;
                    }
                    .blind_side_option {
                        max-width: 4.8rem;
                    }
                    .blind_veneciane_option {
                        max-width: 3.7rem;
                    }
                }
            }
        }
        .dimensionetSasia {
            .inputFields {
                margin-bottom: 1.5rem;
                .inputContent1 {
                    width: 52%;
                }
            }
        }
        .windowTypes {
            .window_selector {
                .displace_windows_evenly {
                    .paretn_bigger_window_type {
                        // margin-left: 11px;
                        padding-top: 11rem;
                        margin-bottom: 3rem;
                        min-height: 15rem;
                    }
                    .tipet_e_dritareve {
                        // &:not(.test){
                        //     margin-left: 40px;
                        // }
                        .dritare_nje_kaheshe_option {
                            max-width: 9rem;
                            min-height: 7rem;
                        }

                        .dere_nje_kaheshe_option {
                            min-width: 9rem;
                            max-height: 15rem;
                            min-height: 11rem;
                        }
                        .dere_dy_kaheshe_option {
                            &:not(.test1) {
                                max-width: 8rem;
                            }
                            max-width: 10rem;
                            min-height: 10rem;
                        }
                    }
                }
                .window_radio_button {
                    &:checked ~ .window_bigger_option_checkmark {
                        height: 20rem;
                        width: 11rem;
                        margin-left: 46px;
                        left: 0.5rem;
                    }
                    &:checked ~ .two_dors_window_option_checkmark {
                        height: 12rem !important;
                        width: 10rem !important;
                    }
                }
            }
        }
    }
}
