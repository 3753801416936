@font-face {
    font-family: "RobotoCondensedBold";
    src: local("AirbnbCerealLight"), url("../../fonts/RobotoCondensed-Bold.ttf") format("truetype");
}
@keyframes fadeMe {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.responsive_mobile_header {
    height: 0;
}

.homepage_layout {
    overflow-x: hidden;
    .slick_mobile_slider {
        opacity: 0;
        height: 0px;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .opening_parent_slider {
        .slick-dots {
            bottom: 20px;
            display: flex !important;
            justify-content: center;
            .slick-active {
                // background-color: #2e34ba;
                // transition:all 5s ease;
                // background-color: linear-gradient(to right, #2e34ba 100%, #f3f5fa 50%) left;

                border-radius: 3px;
                width: 226.12px;
                height: 2px;
                background: linear-gradient(to right, #f3f5fa 50%, #2e34ba 50%);
                background-size: 200% 100%;
                background-position: right bottom;
                transition: all 4s ease-out;
            }
            li {
                transform: rotate(180deg);
                width: 226.12px;
                height: 2px;
                background-color: #f3f5fa;
                button {
                    &::before {
                        content: "";
                    }
                }
            }
        }

        .slick-initialized {
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .images_homepage {
            width: 100%;
            max-height: 100vh;
            min-height: 70vh;
            object-fit: cover;
            object-position: top;
        }
        .ImageDescription {
            font-family: "RobotoCondensedBold";
            position: absolute;
            // left: 8.5%;
            top: 13%;
            color: white;
            text-align: start;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 55px;
            // cursor: pointer;
            // background-color: transparent;
        }
        .lokacioni {
            display: flex;
            position: absolute;
            top: 31%;
            font-style: normal;
            // left: 8.5%;
            justify-content: center;
            align-items: baseline;
            .emri {
                color: white;
                font-weight: 400;
            }
            .emriNderteses {
                color: white;
                margin-right: 40px;
                font-weight: 600;
                align-items: baseline;
            }
            i {
                font-size: x-large;
                color: white;
            }
        }
        .shikoProjektin {
            position: absolute;
            top: 80%;
            // left: 8.5%;
            z-index: 9;
            // max-width: 190px;
            // min-width: 190px;
            width: fit-content;
            padding-left: 0.2%;
            line-height: 40px;
            padding-right: 0.2%;
            text-align: center;
            text-decoration: none;
            color: white;
            font-weight: 700;
            font-size: 18px;
            border-color: white;
            border-radius: 2px;
            border: 2px solid #ffffff;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(0, 0, 0, 0.5);
            // opacity: 1.8%;
        }
    }

    .slide {
        .carousel-indicators {
            #butonatSlider {
                width: 226.12px !important;
                height: 2px;
            }
            .active {
                background-color: #2e34ba;
                // width: 226.12px !important;
                // transform: matrix(1, 0, 0, -1, 0, 0);
            }
        }
        .carousel-inner {
            .carousel-item {
                .ImageDescription {
                    font-family: "RobotoCondensedBold";
                    position: absolute;
                    // left: 8.5%;
                    top: 12%;
                    color: white;
                    text-align: start;
                    font-size: 50px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 55px;
                    // background-color: transparent;
                }
                .lokacioni {
                    display: flex;
                    position: absolute;
                    top: 31%;
                    font-style: normal;
                    // left: 8.5%;
                    justify-content: center;
                    align-items: baseline;
                    .emri {
                        color: white;
                        font-weight: 400;
                    }
                    .emriNderteses {
                        color: white;
                        margin-right: 40px;
                        font-weight: 600;
                        align-items: baseline;
                    }
                    i {
                        font-size: x-large;
                        color: white;
                    }
                }
                .shikoProjektin {
                    position: absolute;
                    top: 58%;
                    // left: 8.5%;
                    z-index: 9;
                    // max-width: 190px;
                    // min-width: 190px;
                    width: fit-content;
                    padding-left: 2%;
                    padding-right: 2%;
                    text-align: center;
                    text-decoration: none;
                    color: white;
                    font-weight: 700;
                    font-size: 20px;
                    border-color: white;
                    border-radius: 2px;
                    border: 2px solid #ffffff;
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
                        rgba(0, 0, 0, 0.5);
                    // opacity: 1.8%;
                }
            }
        }
    }
    @keyframes fade-in {
        0% {
            transform: translateY(-10px);
        }
        15% {
            transform: translateY(-250px);
        }
        30% {
            transform: translateY(-50px);
        }
        45% {
            transform: translateY(-75px);
        }
        60% {
            transform: translateY(-100px);
        }
        75% {
            transform: translateY(-125px);
        }
        90% {
            transform: translateY(-150px);
        }
        100% {
            transform: translateY(-175px);
        }
    }
    @keyframes transform-fade-in {
        0% {
            transform: scale(1);
        }
      
        100% { transform: scale(0.9);
        }
    }
    .materialet_punuese_container {
        margin-top: 3rem;
        margin-bottom: 4rem;
        .materialet_punuese {
            min-height: 650px;
            max-height: 650px;
            background: linear-gradient(80.04deg, #c0cce2 56.3%, #dadfe8 103.52%);
            .porduketTitle {
                font-family: "Roboto-Medium";
                font-weight: 500;
                font-size: 40px;
                text-align: center;
                color: #232d58;
            }
            .aranzhoCardat {
                justify-content: space-around;
                .card {
                    text-decoration: none;
                    background-color: transparent;
                    border: none;

                    &:hover {
                        .description {
                            animation-name: fade-in;
                            animation-duration: 0.5s;
                            animation-fill-mode: forwards;
                        }
                        .card-img-top {
                            animation-name: transform-fade-in;
                            animation-duration: 0.5s;
                            animation-fill-mode: forwards;
                        }
                    }

                    .card-img-top {
                        transition: all 0.3s ease-in-out;
                        height: 323px;
                        // width:323px;
                    }

                    .description {
                        display: block;
                        // margin-top: -200px;
                        opacity: 1;
                        .cardTitle {
                            font-style: normal;
                            color: #232d58;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 28px;
                            text-align: center;
                            letter-spacing: 0.01em;
                        }
                        .card-text {
                            font-family: "Roboto-Regular";
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 26px;
                            text-align: center;
                            color: #232d58;
                        }
                    }
                }
            }
        }
    }

    .descriptionOfWork {
        position: relative;
        background: linear-gradient(80.04deg, rgba(192, 204, 226, 0.2) 56.3%, rgba(218, 223, 232, 0.2) 103.52%);
        mix-blend-mode: multiply;
        opacity: 0.9;
        .image_background {
            // padding: 0;

            .pershkrimiXhamitPVC {
                object-fit: none;

                justify-content: center;
                display: flex;
                align-items: center;
                margin: auto auto;
                height: 70vh;
                object-fit: contain;
                width: 82vh;
            }
        }
        .image_description_background {
            // height: 70vh;

            background: linear-gradient(71.76deg, #2e55ba -18.58%, #1a2246 108.04%);
            .profile_description_title {
                font-family: "Roboto-Medium";
                font-style: normal;
                color: white;
                font-weight: 500;
                font-size: 40px;
                line-height: 52px;
            }
            .profile_description_pharagraph {
                width: 88%;
                font-family: "Roboto-Regular";
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: white;
            }
            .profile_project_button {
                padding: 0 20px 0 20px;
                position: absolute;
                background: rgba(255, 255, 255, 0.9);
                border: 2px solid #ffffff;
                border-radius: 2px;
                color: #1d2853;
                font-weight: 700;
                font-size: 20px;
                line-height: 40px;
                text-align: center;
                text-decoration: none;
                margin-top: 2%;
            }
        }
        .circle1 {
            height: 58px;
            width: 58px;
            background-color: white;
            border-radius: 50px;
            margin-top: 1%;
            margin-left: 23%;
            position: absolute;
            .numri1 {
                margin: 5px 0px 0px 20px;
                align-items: center;
                font-size: xx-large;
            }
        }
        .glass_text {
            display: flex;
            margin: 0px 0px 0px 30px;
            align-items: center;
            font-weight: 400;
            font-size: 20px;
            min-width: max-content;
        }
        .circle2 {
            height: 58px;
            width: 58px;
            background-color: white;
            border-radius: 50px;
            margin-top: 7.5%;
            margin-left: 34%;
            position: absolute;
            .numri2 {
                margin: 5px 0px 0px 20px;
                align-items: center;
                font-size: xx-large;
            }
        }
        .circle3 {
            height: 58px;
            width: 58px;
            background-color: white;
            border-radius: 50px;
            margin-top: 19%;
            margin-left: 33%;
            position: absolute;

            .numri3 {
                height: 58px;
                margin: auto;
                display: flex;
                align-items: center;
                margin-left: 32.5%;
                font-size: xx-large;

                p {
                    font-size: 20px;

                    margin-left: 60%;
                    min-width: max-content;
                    align-items: baseline;
                    display: flex;
                    height: 15px;
                }
            }
        }
    }
    .SliderDiv {
        min-height: auto;
        margin-top: 5%;
        // align-items: center;
        // display: flex;
        height: auto;
        .titulliSlider {
            width: 90%;
            font-family: "Roboto-Medium";
            margin-bottom: 50px;
            font-style: normal;
            color: #232d58;
            font-weight: 500;
            line-height: 45px;
            font-size: 40px;
            text-align: initial;
        }
        .pershkrimiSlider {
            font-family: "Roboto-Light";
            font-weight: 300;
            font-size: 16px;
            line-height: 32px;
            color: #232d58;
            text-align: initial;
            display: flex;
            flex-direction: column;
            .buttoniSlider {
                margin-top: 80px;
                color: white;
                width: 150px;
                height: 40px;
                border-radius: 2px;
                text-align: center;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                border: none;
                background: linear-gradient(46.81deg, #2e55ba -11%, #1a2246 168.73%);
            }
        }
        .parent_complex_slider_div {
            .swiper {
                width: 80%;
                height: auto;
            }

            .swiper-slide {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 22px;
                font-weight: bold;
                color: #fff;
            }

            .swiper-slide:nth-child(1n) {
                // background-color: rgb(206, 17, 17);
            }

            .swiper-slide:nth-child(2n) {
                // background-color: rgb(0, 140, 255);
            }

            .swiper-slide:nth-child(3n) {
            }

            .swiper-slide:nth-child(4n) {
            }

            .swiper-slide:nth-child(5n) {
            }

            .swiper-slide:nth-child(6n) {
            }

            .swiper-slide:nth-child(7n) {
            }

            .swiper-slide:nth-child(8n) {
            }

            .swiper-slide:nth-child(9n) {
            }

            .swiper-slide:nth-child(10n) {
            }

            position: relative;
            .image-wrapper {
                .complexSliderIMG {
                }
            }
            .selector_style {
                margin-top: 3vh;
                margin-bottom: 5%;
                position: relative;
                display: flex;
                justify-content: center;
                width: 100%;

                input[type="range"] {
                    padding-right: 10px;
                    height: 38px;
                    -webkit-appearance: none;
                    width: 100%;
                }
                input[type="range"]:focus {
                    outline: none;
                }

                // this is the code for arrows in the slider

                // input[type="range"]::after {
                //         content:"< >";
                //         position: absolute;
                //         color: white;
                //         background-color: red;
                //         top: 5px;
                //         margin-left: 8px;
                //         width: 100px;
                //         height: 100px;
                //         // text-anchor: middle;
                //         // font-weight: 700;
                //         // font-size: 14px;
                //         // color: red;
                //         // letter-spacing: 5px;
                //         // position: absolute;
                //         // user-select: none;
                //         // -webkit-user-select: none;
                //         // pointer-events: none;
                //         // text-rendering: optimizeSpeed;
                //     }
                input[type="range"]::-webkit-slider-runnable-track {
                    width: 100%;
                    height: 2px;
                    cursor: pointer;
                    animate: 0.2s;
                    color: #1a2246;
                    background: #e6e6e6;
                    border-radius: 5px;
                    outline: none;
                }
                input[type="range"]::-webkit-slider-thumb::after {
                    content: "< >";
                    color: #1a2246;

                    height: 50px;
                    width: 50px;
                    font-size: xx-large;
                }
                input[type="range"]::-webkit-slider-thumb {
                    background: url("../../images/DragAndDiscover.svg");
                    box-shadow: 1px 1px 1px #1a2246;
                    color: #1a2246;
                    height: 45px;
                    width: 45px;
                    border-radius: 50px;
                    object-fit: cover;
                    background-position: center;
                    background-size: 105%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -22px;
                    outline: none;
                }
                input[type="range"]:focus::-webkit-slider-runnable-track {
                    outline: none;
                    border: none;
                }

                // mozilla scss

                input[type="range"]::-moz-range-track {
                    width: 100%;
                    height: 2px;
                    cursor: pointer;
                    animate: 0.2s;
                    color: #1a2246;
                    background: #e6e6e6;
                    border-radius: 5px;
                    outline: none;
                }
                input[type="range"]::-moz-range-thumb::after {
                    content: "< >";
                    height: 50px;
                    width: 50px;
                    font-size: xx-large;
                }
                input[type="range"]::-moz-range-thumb {
                    border: 1px solid #1a2246;
                    background: url("../../images/DragAndDiscover.svg");
                    box-shadow: 1px 1px 1px #1a2246;
                    color: #1a2246;
                    height: 45px;
                    width: 45px;
                    border-radius: 50px;
                    object-fit: cover;
                    background-position: center;
                    background-size: 105%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    -webkit-appearance: none;
                    margin-top: -22px;
                    outline: none;
                }
                input[type="range"]:focus::-moz-range-track {
                    outline: none;
                    border: none;
                }
                // end of mozilla scss

                .drag_and_discover_text {
                    color: grey;
                    align-items: center;
                    display: flex;
                    max-width: fit-content;
                }
            }
        }
    }
    .imazhiPrishtineDiv {
        display: grid;
        .imazhiPrishtinesMobile {
            opacity: 0;
            height: 0;
        }
        .imazhiPrishtines {
            width: 100%;
        }
        .pershkrimiPrishtine {
            position: absolute;
            text-align: initial;
            margin-left: 10%;
            margin-top: 4%;
            .TitulliPrishtine {
                font-family: "Roboto-Medium";
                font-weight: 500;
                font-size: 40px;
                line-height: 45px;
                color: #232d58;
                // max-width: 60% !important;
            }
            .vizaNdarese {
                background: #232d58;
                transform: matrix(1, 0, 0, -1, 0, 0);
                height: 2.06px;
            }
            ul {
                li {
                    font-family: "Roboto-Light";
                    color: #232d58;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 43px;
                    letter-spacing: 0.02em;
                }
            }
        }
    }
    .projektetErealizuara {
        height: auto;
        text-align: center;
        align-items: center;
        .titulliProjekteve {
            font-family: "Roboto-Medium";
            color: #232d58;
            font-size: 40px;
            font-weight: 500;
            text-align: center;
            line-height: 55px;
        }
        .pershkrimiProjekteveTeRealizuara {
            font-family: "Roboto-Light";
            margin: 0 auto;
            text-align: center;
            width: auto;
            font-weight: 300;
            font-size: 16px;
            line-height: 32px;
            color: #232d58;
        }
        .singleCard {
            position: relative;
            margin: 15px auto;
            color: white;
            .imazhi {
                background-repeat: no-repeat;
                background-size: cover;
                height: 415px;
                background-position: center;

                &:hover {
                    // transform: scale(1.1);
                    cursor: pointer;
                    // position: relative;
                    // width: 100%;
                    // margin: 15px auto;
                }
            }
            .image_info {
                width: 96.4%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                color: #ffffff;
                background: linear-gradient(180deg, rgba(34, 34, 34, 0) 0%, #222222 100%);
                mix-blend-mode: normal;
                height: 144px;
                position: absolute;
                bottom: 0;
                .cardTitle {
                    position: absolute;
                    // top: 60%;
                    margin-left: 5%;
                    text-align: initial;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 40px;
                }

                .cardText {
                    font-family: "Roboto-Light";
                    position: absolute;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 23px;
                    // top: 73%;
                    margin-left: 5%;
                    text-align: initial;
                    .locationMarker {
                        padding-right: 5px;
                    }
                }
                .gradient_background {
                }
            }
            p {
            }
        }
    }
    .partneretTane {
        text-align: center;
        img {
            margin: 1% 1%;
            width: 136px;
            height: 61px;
        }
    }
}
.our_staff_content {
    .our_staff_page_title {
        font-family: "Roboto-Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 55px;
        
        color: #232d58;
    }
    .staff-name {
        font-family: "RobotoCondensedBold";
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: -5px;
        color: #232d58;
        font-size: 24px;
        font-weight: 500;

    }
    .staff_image{
        max-width: 196px;
        max-height: 244px;
    }
    .company_description{
        line-height: 20px;
        font-size: 16px;
        // margin-top: 8px;
    }
    .our_staff_inside_company_image {
        width: 100%;
    }
}

// ////////////////////////////////////////////////////////////////
// ///////////////////// RESPONSIVITETI MOBIL /////////////////////
// ////////////////////////////////////////////////////////////////

@media only screen and (max-width: 800px) {
    .homepage_layout {
        padding-top: 3.6em;
        .opening_parent_slider {
            .images_homepage {
                height: 90vh;
            }
            .ImageDescription {
                font-size: 31px;
                width: 11%;
                top: 8%;
                line-height: 34px;
            }
            .lokacioni {
                flex-direction: column;
                top: 35%;
                .emri {
                    font-size: 15px;
                }
                .emriNderteses {
                    font-size: 15px;
                }
                i {
                    font-size: 15px;
                }
            }
            .shikoProjektin {
                font-size: 15px;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
        .first_slider_image {
            .images_homepage {
                height: 40rem;
                object-fit: cover;
            }
            .ImageDescription {
                top: 5%;
                font-size: 32px;
                line-height: 38px;
                width: 17rem;
            }
            .lokacioni {
                position: absolute;
                color: white;
                display: block;
                top: 52%;
                .emri {
                    font-size: 18px;
                }
                .emriNderteses {
                    font-size: 18px;
                }
                i {
                    font-size: large;
                    color: white;
                }
            }
            .shikoProjektin {
                top: 70%;
                margin-left: 1.5rem;
                margin-right: 1.5rem;
                border: 1px solid;
                font-size: 16px;
                line-height: 1.7;
            }
        }

        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            opacity: 0;
        }

        .slide {
            .carousel-inner {
                .carousel-item {
                    .images_homepage {
                        height: 90vh;
                        object-fit: cover;
                    }
                    .ImageDescription {
                        top: 5%;
                        font-size: 32px;
                        line-height: 38px;
                        min-width: fit-content;
                    }
                    .lokacioni {
                        display: block;
                        top: 52%;
                        left: 4%;
                        .emri {
                            font-size: 18px;
                        }
                        .emriNderteses {
                            font-size: 18px;
                        }
                        i {
                            font-size: large;
                            color: white;
                        }
                    }
                    .shikoProjektin {
                        top: 70%;
                        padding-left: 5.5%;
                        padding-right: 5.5%;
                        border: 1px solid;
                        font-size: 16px;
                        line-height: 1.7;
                    }
                }
            }
        }
        .slick_mobile_slider {
            opacity: 1;
            height: auto;
            min-height: 40rem;
            overflow-y: hidden;
            overflow-x: hidden;
            .slick-dots {
                bottom: -30px;
            }
            .prorduketTitle {
                font-weight: 500;
                font-size: 22px;
                text-align: center;
                color: #232d58;
                margin-bottom: 0;
            }
            .description {
                width: 90%;
                margin: auto;
                .cardTitle {
                    font-style: normal;
                    color: #232d58;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                    letter-spacing: 0.01em;
                }
                .card-text {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: #232d58;
                }
            }
        }
        .materialet_punuese_container {
            display: none;
            margin-top: 0px;
            margin-bottom: 0px;
            overflow-x: hidden;
            .materialet_punuese {
                opacity: 0;
                height: 0px;
                width: 0px;
            }
        }
        .imazhiPrishtineDiv {
            margin-bottom: 100%;
            .imazhiPrishtines {
                opacity: 0;
                display: none;
            }
            .imazhiPrishtinesMobile {
                width: 100%;
                opacity: 1;
                height: 666px;
                position: absolute;
                padding-top: 305px;
            }
            .pershkrimiPrishtine {
                margin-left: 0px;
                width: 90%;
                margin: auto;
                position: relative;
                .TitulliPrishtine {
                    font-weight: 500;
                    font-size: 22px;
                    text-align: center;
                    line-height: 25px;
                    color: #232d58;
                    margin-bottom: 15px;
                }
                .vizaNdarese {
                    display: none;
                    width: 90%;
                    margin: 0px auto 10px auto;
                    background: #232d58;
                    transform: matrix(1, 0, 0, -1, 0, 0);
                    height: 2.06px;
                }
                ul {
                    li {
                        color: #232d58;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        letter-spacing: 0.02em;
                    }
                }
            }
        }
        .descriptionOfWork {
            .image_background {
                // margin-top: 35rem;
                .pershkrimiXhamitPVC {
                    margin-top: 38rem !important;
                    margin: auto auto;
                    height: 40vh;
                    width: auto;
                    object-fit: contain;
                }
            }
            .image_description_background {
                position: absolute;
                height: 38rem;
                .profile_description_title {
                }
                .profile_description_pharagraph {
                }
                .profile_project_button {
                }
            }
            .circle1 {
                height: 40px;
                width: 40px;
                margin-top: 4%;
                margin-left: 45%;
                .numri1 {
                    height: 42.5px;
                    margin: 0px 0px 0px 12px;
                }
            }
            .glass_text1 {
                color: white;
                font-size: 15px;
            }
            .circle2 {
                margin-top: 24%;
                margin-left: 66%;
                height: 40px;
                width: 40px;
                .numri2 {
                    height: 42.5px;
                    margin: 0px 0px 0px 12px;
                }
                .glass_text2 {
                    margin: 0px 0px 0px -133px;
                    color: white;
                }
            }
            .circle3 {
                height: 40px;
                width: 40px;
                margin-top: 56%;
                margin-left: 71.5%;
                .numri3 {
                    height: 42.5px;
                    margin: 0px 0px 0px 12px;
                }
                .glass_text3 {
                    color: white;
                    margin: 0px 0px 0px -133px;
                }
            }
        }
        .SliderDiv {
            // margin-top: 90vh;
            margin-bottom: 3%;
            .titulliSlider {
                width: 100%;
                font-size: 22px;
                text-align: center;
                margin-bottom: 0;
                line-height: 30px;
            }
            .pershkrimiSlider {
                text-align: center;
                width: 80%;
                margin: auto;
                line-height: 22px;

                .buttoniSlider {
                }
            }
            .parent_complex_slider_div {
                margin-top: 1rem;
                .image-wrapper {
                    .complexSliderIMG {
                        max-width: 93.3%;
                    }
                }
                .selector_style {
                    input[type="range"] {
                    }
                }
            }
        }
        .projektetErealizuara {
            margin-top: 400px !important;
            .titulliProjekteve {
                font-size: 22px;
                line-height: 30px;
            }
            .pershkrimiProjekteveTeRealizuara {
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                width: 92%;
                letter-spacing: 0.02em;
            }
            .singleCard {
                margin: 15px auto 0px auto;
                .imazhi {
                    &:hover {
                    }
                }
                .image_info {
                    width: 93.4%;
                    .cardTitle {
                    }

                    .cardText {
                        .locationMarker {
                        }
                    }
                    .gradient_background {
                    }
                }
                p {
                }
            }
        }
        .partneretTane {
            img {
                object-fit: contain;
                width: 85px;
                // margin: auto;
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
}
