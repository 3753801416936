.about_us_layout {
    .about_us_company_image {
    }
    .about_us_content {
        .about_us_page_title {
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 55px;
            color: #222222;
        }
        .company_description {
            font-family: "Roboto-Light";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 32px;
            color: #222222;
        }
    }
    .informativ_text_for_user {
        font-size: 44px;
        color: #232d58;
        margin-bottom: 2.5rem;
        font-family: "Roboto-Medium";
        font-style: normal;
        font-weight: 500;
        line-height: 45px;
        text-align: center;
    }
    .send_to_contact_you_text {
        font-family: "Roboto-Regular" !important;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #232d58;
        text-align: center;
        width: 50%;
        margin: auto;
    }
    .personal_data_input_fields {
        text-align: center;

        .inputContent {
            .name_and_surname {
                font-family: "Roboto-Regular";
                position: absolute;
                pointer-events: none;
                margin-left: -25rem;
                transition: 0.5s;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 40px;
                letter-spacing: 0.02em;
                color: #222222;
            }

            input {
                width: 100%;
                height: 40px;
                padding-left: 7px;
                border: none;
                background: #ffffff;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                border-radius: 6px;
            }
            .inputContent-message {
                width: 100%;
                min-height: 150px;
                height: auto;
                padding-left: 7px;
                border: none;
                background: #ffffff;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                border-radius: 6px;
            }

            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type="number"] {
                -moz-appearance: textfield;
            }

            input:valid ~ span,
            input:focus ~ span {
                transform: translateX(-12px) translateY(-33.2px);
            }
        }
        .send_order_and_button {
            text-align: center;
            .send_this_order {
                font-family: "Roboto-Regular";
                min-width: 232px;
                border: none;
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 43px;
                text-align: center;
                letter-spacing: 0.02em;
                // text-transform: none;
                color: #ffffff;
                background: #1a2246;
                box-shadow: 2px 4px 33px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                min-width: 175px;

                font-weight: 600;
                font-size: 17px;
                line-height: 40px;
            }
        }
    }
    .partneretTane {
        text-align: center;
        img {
            margin: 1% 1%;
            width: 136px;
            height: 61px;
        }
    }
}
@media only screen and (max-width: 800px) {
    .about_us_layout {
        .about_us_company_image {
            padding-top: 3.6rem;
            line-height: 0;
            object-fit: cover;
            height: 30rem;
            object-position: 0 0;
        }
        .about_us_content {
            .about_us_page_title {
                font-size: 32px;
                margin-top: 1rem !important;
            }
            .company_description {
                font-size: 16px;
                line-height: 25px;
            }
            .about_us_inside_company_image {
            }
        }
        .send_to_contact_you_text{
            width: 100%;
        }
        .partneretTane {
            img {
                object-fit: contain;
                width: 85px;
                // margin: auto;
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
}
